* {
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    margin: 0;
}


:root {
    --color-primary-bg: #e5e5e5;
    --color-primary: #0DB9E9;
    --color-primary-200: #9EE3F6;
    --color-secondary: #0D7FE9;
    --color-pending: #F7CB57;
    --color-danger: #F25855;
    --color-success: #00D682;
    --color-tertiary-01: #151A3D;
    --color-tertiary-02: #0DB9E9;
    --color-secondary-50: #E6F7FD;
    --color-secondary-100: #CDEEFC;
    --color-secondary-200: #8ED9F4;
    --color-label: #9598A3;
    --color-label-25: #EFEFF1;
    --color-label-50: #DFE0E4;
    --color-label-100: #C3C5CC;
    --color-label-200: #9598A3;
    --color-label-300: #575B68;
    --color-label-400: #1E202A;
    --color-575B68: #575B68;
    --color-767E85: #767E85;
    --color-2FB9F9: #2FB9F9;
    --color-CFE5FB: #CFE5FB;
    --color-E0F7EA: #E0F7EA;
    --color-00BE4C: #00BE4C;
    --color-FDEBEB: #FDEBEB;
    --color-F25855: #F25855;
    --color-4C97FA: #4C97FA;
    --color-primary-01: #0DB9E9;
    --color-black: #000000;
    --color-gray-100: #F5F8FA;
    --color-gray-200: #E1E7EC;
    --color-gray-500: #8895A7;
    --color-gray-700: #212934;
    --color-white: #FFFFFF;
    --color-secondary-01: #0D7FE9;
    --color-primary-02: #F2F2F2;
    --color-logo-background: #212934;
    --color-button-background: #d6d8dd;
    --color-green-600: #00B058;
    --color-solid-1: #FFF8E7;
    --color-solid-2: #775D00;
    font-size: 10px;
}

body {
    background-color: var(--color-primary-bg);
}


.cursor-pointer {
    cursor: pointer;
}


/*.highlighted-border {*/
/*    border: 0.2rem solid var(--color-danger) !important;*/
/*    border-radius: 0.25rem;*/
/*}*/


.highlighted-border {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}


.highlighted-background {
    background-color: var(--color-danger) !important;
}

.highlighted-foreground {
    color: var(--color-danger) !important;
}

.textInputField {
    height: 3.5rem !important;
    border-radius: 0.25rem !important;
    padding: 1rem 1rem 1.3125rem 1rem !important;
    font-size: 0.9375rem !important;
    line-height: 1.1875rem !important;
    font-weight: 600 !important;
    margin: 0 !important;
    color: var(--color-label-400) !important;
}

.textInputField::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-label-200) !important;
    font-weight: 400 !important;
    opacity: 1;
    /* Firefox */
}


.switchInput {
    transform: scale(0.6);
    transform-origin: left;
    font-size: 1rem !important;
    color: var(--color-label-200) !important;
    font-weight: 400 !important;
}

.switchInput.active {
    color: var(--color-label-400) !important;
    font-weight: 600 !important;
}


.marvel-device.iphone-x .camera {
    left: 104px !important;
}

.buttonItem.needActiveValidation {
    cursor: not-allowed !important;
}

.buttonItem.needActiveValidation.active {
    cursor: pointer !important;
}

.marvel-device.iphone-x .notch {
    left: 86px !important;
    width: 130px !important;
    top: 12px !important;
    height: 25px !important;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.marvel-device.iphone-x {
    padding: 17px !important;
}

.marvel-device.iphone-x .inner-shadow {
    box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 66%)
}


.marvel-device.iphone-x .screen {
    border-radius: 50px !important;
}